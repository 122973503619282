<template>
  <CCard>
    <CCardHeader class="vertical-center d-inline-flex flex-gap-2">
      <h5 class="mb-0">
        {{ $route.meta.label }}
        <template v-if="form?.id && !spinners.loading">
          : <h3 class="d-inline"><CBadge color="secondary">#{{ form.id }}</CBadge></h3>
        </template>
      </h5>

      <div class="ml-auto">
        <CButton color="dark" :to="{ name: 'Merchandise Orders' }">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <form-summary
        v-if="!isValid"
        class="form-errors alert alert-danger"
        :validator="$v.form"
        :attributes="attributes"
      />

      <CForm class="merchandise-order-form" v-if="!spinners.loading">
        <div class="d-flex flex-column">
          <!-- Merchant -->
          <div class="mb-3 d-flex flex-column flex-md-row">
            <div class="form-group w-100">
              <label class="d-block">Merchant:</label>
              <v-select
                placeholder="Select restaurant.."
                v-model="$v.form.merchant_id.$model"
                :options="allRestaurants"
                :loading="spinners.loading && allRestaurants.length <= 0"
                :reduce="option => option.value"
              />
            </div>
          </div>

          <!-- Order Details -->
          <div class="mb-2 d-flex flex-column flex-md-row flex-gap-2">
            <CInput
              type="date"
              class="w-100"
              label="Order Date:"
              :value.sync="$v.form.order_date.$model"
            />

            <CInput
              type="number"
              class="w-100"
              label="Amount"
              placeholder="0.00"
              :value.sync="$v.form.total_amount.$model"
              step="0.01"
              pattern="^\d+(?:\.\d{1,2})?$"
            >
              <template #prepend-content><CIcon name="cil-euro"/></template>
            </CInput>
          </div>

          <!-- Description -->
          <div class="mb-0 form-group w-100">
            <CTextarea
              class="mb-0"
              label="Notes:"
              placeholder="Additional notes about the order"
              rows="3"
              :value.sync="$v.form.description.$model"
            />
          </div>
        </div>
      </CForm>

      <CSpinner v-else color="primary" />
    </CCardBody>

    <CCardFooter class="sticky-bottom">
      <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
        <CButton
          color="primary"
          :disabled="spinners.loading || spinners.btnSubmit || !form.merchant_id"
          @click="submit()"
        >
          <CSpinner v-if="spinners.btnSubmit" size="sm" /> Submit
        </CButton>
      </div>
    </CCardFooter>
  </CCard>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { validationMixin } from "vuelidate"
import { required, minValue } from "vuelidate/lib/validators"
import _debounce from 'lodash/debounce';

export default {
  name: 'MerchandiseOrder',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        id: null,
        merchant_id: null,
        merchant_type: 'App\\Models\\Restaurant',
        order_date: null,
        total_amount: 0,
        description: null
      },

      spinners: {
        loading: false,
        btnSubmit: false
      },

      unsavedChanges: false,

      // Vuelidate error attributes
      attributes: {
        merchant_id: 'Merchant',
        order_date: 'Order date',
        total_amount: 'Amount',
      }
    }
  },

  validations: {
    form: {
      merchant_id: { required },
      merchant_type: { },
      order_date: { required },
      total_amount: { required, minValue: minValue(0) },
      description: { },
    }
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapGetters('restaurants', ['allRestaurants']),

    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    itemId() { return Number(this.$route.params.id) || null },
    transformedForm() {
      const { merchant, invoice, ...rest } = this.form;

      return {
        ...rest
      }
    },
  },

  async mounted() {
    // F5 veya başka bir siteye gitmeden önce 'Yaptığınız değişiklikler kaydedilmemiş olabilir.' koruması için
    window.onbeforeunload = () => this.unsavedChanges === true ? true : null;

    this.$watch('form', { handler: 'watchForm', deep: true });

    await this.fetchAllRestaurants();

    if (this.itemId) {
      await this.getForm(this.itemId);
    }

    setTimeout(() => {
      sessionStorage.setItem(`merchandise-order-form`, JSON.stringify(this.transformedForm));
      this.unsavedChanges = false;
    }, 500);
  },

  methods: {
    ...mapActions('restaurants', ['fetchAllRestaurants']),

    checkIfValid (fieldName) {
      const fields = fieldName.split(".");
      const field = fields.length > 1
        ? this.$v.form[fields[0]][fields[1]]
        : this.$v.form[fieldName];

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "");
    },

    validate() {
      this.$v.$touch()
    },

    watchForm(newV, oldV) {
      const initialState = sessionStorage.getItem(`merchandise-order-form`);
      const newState = JSON.stringify(this.transformedForm);

      this.unsavedChanges = initialState !== newState;
    },

    async getForm(id) {
      this.spinners.loading = true;

      try {
        const { data } = await this.$axios.get(
          this.$backend.MERCHANDISE_ORDERS.SHOW.replace("{id}", id)
        );

        const order = data.data;

        this.form = {
          id: order.id,
          merchant_id: order.merchant_id,
          merchant_type: order.merchant_type,
          order_date: order.order_date,
          total_amount: order.total_amount,
          description: order.description
        };
      } catch (error) {
        this.$toast.error(error.response.data.message);
        setTimeout(() => { this.$router.push({ name: 'Merchandise Orders' }) }, 3000);
      } finally {
        this.spinners.loading = false;
        this.unsavedChanges = false;
      }
    },

    async submit() {
      if (!this.isValid) {
        this.$v.$touch();
        return;
      }

      this.spinners.btnSubmit = true;

      try {
        if (this.itemId) {
          await this.update();
        } else {
          await this.store();
        }
      } finally {
        this.spinners.btnSubmit = false;
      }
    },

    async store() {
      try {
        const { data } = await this.$axios.post(
          this.$backend.MERCHANDISE_ORDERS.STORE,
          this.form
        );

        this.$toast.success('Merchandise order created successfully');
        this.unsavedChanges = false;

        setTimeout(() => { this.$router.push({ name: 'Merchandise Orders' }); }, 2000);
      } catch (error) {
        this.$toast.error(
          typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
        );
      }
    },

    async update() {
      try {
        const { data } = await this.$axios.put(
          this.$backend.MERCHANDISE_ORDERS.UPDATE.replace("{id}", this.itemId),
          this.form
        );

        this.$toast.success('Merchandise order updated successfully');
        this.unsavedChanges = false;
      } catch (error) {
        this.$toast.error(
          typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
        );
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.unsavedChanges === true) {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      this.unsavedChanges = !answer;
      next(answer) // answer: true or false
    } else {
      next()
    }
  }
}
</script>

<style>
.merchandise-order-form .form-control,
.merchandise-order-form .vs__search,
.merchandise-order-form .vs__dropdown-menu,
.merchandise-order-form .vs__selected {
  font-size: 1.125rem;
  color: #9b0a0a;
  font-weight: 500;
}

.merchandise-order-form .vs__dropdown-toggle {
  padding: 2px 0 6px;
}
</style>
